import React, { useRef, forwardRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot';

import {
	bindClusterEvents,
	bindCursorEvents,
	convertArrayToGeoJson,
	fitMapToBounds,
	createFontAwesomeMarker,
	setActiveListItem,
	createPopUp,
	mergeTitles,
} from './helpers';

mapboxgl.accessToken =
	'pk.eyJ1IjoibnRlLWFzIiwiYSI6ImNrcTI0amZsdTBmMDMyd3BmdTh3eDN6bTcifQ.nxgd5HQICkKnQp-OREqkPQ'; // NTE´s Mapbox Account

//#region Styling
const Wrapper = styled.div`
	height: 500px;
	overflow: hidden;
	position: relative;
	${p =>
		p.theme.media.medium(css`
			border-radius: ${p => p.theme.utils.borderRadius};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			height: 350px;
		`)}
`;

const MapContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	${p =>
		p.theme.media.medium(css`
			border-radius: ${p => p.theme.utils.borderRadius};
		`)}

	.map-popup {
		z-index: 1000;
	}
	.mapboxgl-popup {
		max-width: 250px !important;
		${p =>
			p.theme.media.medium(css`
				max-width: 400px !important;
			`)}
		h3 {
			font-family: ${p => p.theme.typography.body.fontFamily};
			margin: 0;
			font-size: 18px;
			line-height: 24px;
			${p =>
				p.theme.media.medium(css`
					font-size: 20px;
					line-height: 26px;
				`)}
		}
		button:not(.mapboxgl-popup-close-button) {
			color: ${p => p.theme.colors.blue600};
			background: transparent;
			padding: 0;
			font-size: 14px;
			line-height: 22px;
			text-decoration: underline;
			&:hover {
				background-color: transparent;
				color: ${p => p.theme.colors.blue800};
			}
			&:focus-visible {
				outline: 0;
			}
		}
		p {
			font-family: ${p => p.theme.typography.body.fontFamily};
			font-size: 14px;
			line-height: 22px;
			margin: 0;
		}
		ul {
			padding-left: 15px;
			text-align: left;
			margin: 0;
			li {
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	.mapboxgl-popup-content {
		text-align: left;
		padding: 15px;
		min-width: 200px;
		background: white;
		box-shadow: ${p => p.theme.utils.boxShadowHard};
		border-radius: ${p => p.theme.utils.borderRadius};
		${p =>
			p.theme.media.medium(css`
				padding: 20px;
				min-width: 250px;
			`)}
		a {
			color: ${p => p.theme.colors.blue600};
			outline: none;
		}
		.markers {
			.marker {
				&:not(:last-of-type) {
					margin-bottom: 10px;
					${p =>
						p.theme.media.medium(css`
							margin-bottom: 20px;
						`)}
				}
			}
			&.markers-multiple {
				max-height: 250px;
				max-width: 350px;
				overflow: auto;
				padding-right: 10px;
				.marker h3 {
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
	.mapboxgl-popup-close-button {
		color: ${p => p.theme.colors.black};
		outline: none;
		${p =>
			p.theme.media.medium(css`
				font-size: 22px;
				right: 5px;
				top: 5px;
			`)}
		&:hover {
			background-color: transparent;
			color: ${p => p.theme.colors.blue600};
		}
	}
`;

/**
 * Map component with markers and clusters
 * @param {Object} props - Component props
 * @param {string} props.source - Source for the map
 * @param {boolean} props.cluster - Whether to cluster the markers
 * @param {string} props.icon - Font Awesome icon class
 * @param {boolean} props.iconBg - Whether to use a background for the marker icon
 * @param {boolean} props.showId - Whether to show the ID with the title in the popup (default: false)
 * @param {number} props.zoom - Zoom level
 * @param {Array} props.markers - Array of markers
 * @param {string} props.className - Component class name
 * @param {Object} props.style - Component style
 * @param {Function} props.popupLinkClick - Function to handle click on link inside the popup
 */
const Map = forwardRef(
	(
		{
			source = 'locations',
			cluster = true,
			icon = 'fa-regular fa-location-dot',
			iconBg = true,
			showId = false,
			zoom = 7,
			markers = [],
			className = 'map',
			style,
			popupLinkClick = () => {},
		},
		ref
	) => {
		return (
			<Wrapper style={style} className={className} ref={ref}>
				<MapContainer data-cy="map">
					<MapComponent
						cluster={cluster}
						source={source}
						geoJsonFeatures={convertArrayToGeoJson(
							markers?.map(m => m)
						)}
						icon={icon}
						iconBg={iconBg}
						showId={showId}
						zoom={zoom}
						popupLinkClick={popupLinkClick}
					/>
				</MapContainer>
			</Wrapper>
		);
	}
);

export default Map;

/**
 * Initialize the map with markers and clusters
 * @param {Object} containerRef - Ref to the map container
 * @param {boolean} cluster - Whether to cluster the markers
 * @param {string} source - Source for the map
 * @param {Array} geoJsonFeatures - Array of GeoJSON features
 * @param {string} icon - Font Awesome icon class
 * @param {boolean} iconBg - Whether to use a background for the marker icon
 * @param {boolean} showId - Whether to show the ID with the title in the popup
 * @param {number} zoom - Zoom level
 * @param {Function} popupLinkClick - Function to handle click on link inside the popup
 * @returns {void}
 */
const MapComponent = ({
	cluster = true,
	source = '',
	geoJsonFeatures = [],
	icon = 'fa-regular fa-location-dot',
	iconBg = true,
	showId = false,
	zoom = 7,
	popupLinkClick = () => {},
}) => {
	const mapRef = useRef(null);
	const containerRef = useRef(null);
	const markersRef = useRef({});
	const markersOnScreenRef = useRef({});

	useEffect(() => {
		//Initialize the map
		initializeMap({
			mapRef,
			containerRef,
			markersRef,
			markersOnScreenRef,
			cluster,
			source,
			geoJsonFeatures,
			icon,
			iconBg,
			showId,
			zoom,
			popupLinkClick,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update the markers when the geoJsonFeatures change
	useEffect(() => {
		if (
			mapRef.current &&
			mapRef.current.isStyleLoaded() &&
			mapRef.current.isSourceLoaded(source)
		) {
			// Update the source data with the new geoJsonFeatures
			mapRef.current.getSource(source).setData({
				type: 'FeatureCollection',
				features: geoJsonFeatures,
			});

			// Update the markers on the map
			updateMarkers(
				mapRef.current,
				source,
				markersRef.current,
				markersOnScreenRef.current,
				icon,
				iconBg,
				showId,
				popupLinkClick
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoJsonFeatures?.length]);

	return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />;
};

/**
 * Initialize the map with markers and clusters
 * @param {Object} options - Options for the map
 * @param {Object} options.mapRef - Ref to the map object
 * @param {Object} options.containerRef - Ref to the map container
 * @param {Object} options.markersRef - Ref to the markers object
 * @param {Object} options.markersOnScreenRef - Ref to the markers on screen object
 * @param {number} options.zoom - Zoom level
 * @param {boolean} options.cluster - Whether to cluster the markers
 * @param {string} options.source - Source for the map
 * @param {Array} options.geoJsonFeatures - Array of GeoJSON features
 * @param {string} options.icon - Font Awesome icon class
 * @param {boolean} options.iconBg - Whether to use a background for the marker icon
 * @param {boolean} options.showId - Whether to show the ID with the title in the popup
 * @param {Function} options.popupLinkClick - Function to handle click on link inside the popup
 * @returns {void}
 */
async function initializeMap({
	mapRef,
	containerRef,
	markersRef,
	markersOnScreenRef,
	zoom = 7,
	cluster = true,
	geoJsonFeatures = [],
	source = 'locations',
	icon = 'fa-regular fa-location-dot',
	iconBg = true,
	showId = false,
	popupLinkClick = () => {},
}) {
	const modifiedStyle = await fetchAndModifyStyle(); // Fetch and modify the style JSON

	const map = new mapboxgl.Map({
		container: containerRef.current,
		style: modifiedStyle,
		center: [11.3028273, 63.8844802],
		zoom,
		fadeDuration: 0,
	});

	mapRef.current = map;

	library.add(faLocationDot);
	dom.watch();

	// Add navigation control (zoom buttons)
	map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

	map.on('load', () => {
		// Add the source for the GeoJSON data
		map.addSource(source, {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: geoJsonFeatures,
			},
			cluster,
			clusterMaxZoom: 14, // Max zoom to cluster points on
			clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
		});

		// Add cluster layers
		addClusterLayer(map, source);

		// Add markers to the map when the map is rendered
		map.on('render', () => {
			if (!map.isSourceLoaded(source)) return;

			updateMarkers(
				map,
				source,
				markersRef.current,
				markersOnScreenRef.current,
				icon,
				iconBg,
				showId,
				popupLinkClick
			);
		});

		fitMapToBounds(map, geoJsonFeatures, zoom);
		bindClusterEvents(map, source);
		bindCursorEvents(map, source);

		// Change the cursor to a pointer when the mouse is over the unclustered points layer.
		map.on('mouseenter', source, () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', source, () => {
			map.getCanvas().style.cursor = '';
		});
	});

	// Clean up on unmount
	return () => {
		map.remove();
	};
}

/**
 * Fetch and modify the style JSON for the map
 * @returns {Object} - Modified style JSON
 */
async function fetchAndModifyStyle() {
	try {
		// Fetch the existing style JSON
		const response = await fetch(
			`https://api.mapbox.com/styles/v1/mapbox/light-v11?access_token=${mapboxgl?.accessToken}`
		);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const style = await response.json();

		// Define the modifications
		const modifications = [
			{
				id: 'water',
				property: 'paint',
				key: 'fill-color',
				value: '#82CEF0',
			},
			{
				type: 'land',
				property: 'paint',
				key: 'background-color',
				value: '#F0F8FC',
			},
			{
				id: 'national-park',
				property: 'paint',
				key: 'fill-color',
				value: '#F0F8FC',
			},
			{
				id: 'state-label',
				property: 'paint',
				key: 'text-color',
				value: '#000000',
				opacityKey: 'text-opacity',
				opacityValue: 1,
			},
			{
				id: 'settlement-major-label',
				property: 'paint',
				key: 'text-color',
				value: '#000000',
				opacityKey: 'text-opacity',
				opacityValue: 1,
			},
			{
				id: 'settlement-subdivision-label',
				property: 'paint',
				key: 'text-color',
				value: '#000000',
				opacityKey: 'text-opacity',
				opacityValue: 1,
			},
		];

		// Apply the modifications
		modifications.forEach(
			({ id, type, property, key, value, opacityKey, opacityValue }) => {
				const layer = style.layers.find(
					layer => layer.id === id || layer.type === type
				);
				if (layer) {
					layer[property][key] = value;
					if (opacityKey && opacityValue !== undefined) {
						layer[property][opacityKey] = opacityValue;
					}
				}
			}
		);

		return style;
	} catch (error) {
		console.error('Failed to fetch and modify style:', error);
		return null;
	}
}

/**
 * Update the markers on the map
 * @param {Object} map - Map object
 * @param {string} source - Source for the map
 * @param {Object} markers - Object containing the markers
 * @param {Object} markersOnScreen - Object containing the markers on screen
 * @param {string} icon - Font Awesome icon class
 * @param {boolean} iconBg - Whether to use a background for the marker icon
 * @param {boolean} showId - Whether to show the ID with the title in the popup
 * @param {Function} popupLinkClick - Function to handle click on link inside the popup
 * @returns {void}
 */
const updateMarkers = (
	map = {},
	source = '',
	markers = {},
	markersOnScreen = {},
	icon = 'fa-regular fa-location-dot',
	iconBg = true,
	showId = false,
	popupLinkClick = () => {}
) => {
	const newMarkers = {};
	const features = map.querySourceFeatures(source);

	if (!features || !map || !source) return;

	const coordinatesMap = new window.Map();

	features.forEach(feature => {
		if (!feature?.geometry?.coordinates) return;
		const { coordinates } = feature?.geometry;
		const props = feature?.properties;

		// Skip clusters
		if (props.point_count) return;

		const id = props?.id;
		const coordinatesKey = coordinates.join(',');

		if (!coordinatesMap.has(coordinatesKey)) {
			coordinatesMap.set(coordinatesKey, []);
		}

		const featuresAtCoords = coordinatesMap.get(coordinatesKey);

		// Check if the id already exists in the array
		const idExists = featuresAtCoords.some(f => f.id === id);

		if (!idExists) {
			featuresAtCoords.push({ id, props });
		}
	});

	coordinatesMap.forEach((markersWithSameCoords, coordinatesKey) => {
		const [lng, lat] = coordinatesKey?.split(',').map(Number);
		const coordinates = [lng, lat];

		const ids = markersWithSameCoords?.map(f => f.id);
		const uniqueTitles = mergeTitles(
			markersWithSameCoords.map(f => f.props.name)
		);

		const primaryMarker = markersWithSameCoords[0];
		const { props } = primaryMarker;

		if (!markers[ids[0]]) {
			// Create a Font Awesome marker
			const el = createFontAwesomeMarker({
				iconClass:
					markersWithSameCoords?.length > 1
						? 'fa-solid fa-hands-clapping'
						: props?.icon || icon,
				iconBg,
				bgColor: !iconBg
					? 'transparent'
					: props?.markerSize === 'small'
					? 'green'
					: 'blue',
				size: props?.markerSize === 'small' ? 28 : 40,
			});

			el.setAttribute('title', uniqueTitles);
			el.setAttribute('aria-label', uniqueTitles);

			// Add an event listener to the marker
			el.addEventListener('click', () => setActiveListItem(ids[0]));

			// Create a popup
			const popup = createPopUp({
				coordinates,
				source,
				onClick: popupLinkClick,
				markers: markersWithSameCoords,
				showId,
			});

			// Create a marker object
			markers[ids[0]] = new mapboxgl.Marker({ element: el })
				.setPopup(popup)
				.setLngLat(coordinates);
		}

		// Add the marker to the new markers object
		newMarkers[ids[0]] = markers[ids[0]];

		// Add markers that are not yet visible
		if (!markersOnScreen[ids[0]]) markers[ids[0]].addTo(map);
	});

	// Remove markers that are no longer visible
	Object.keys(markersOnScreen).forEach(id => {
		if (!newMarkers[id]) {
			markersOnScreen[id].remove();
			delete markersOnScreen[id];
		}
	});

	// Update markersOnScreen in place
	Object.keys(newMarkers).forEach(id => {
		markersOnScreen[id] = newMarkers[id];
	});
};

/**
 * Add cluster layers to the map
 * @param {Object} map - Map object
 * @returns {void}
 */
export function addClusterLayer(map, source = '') {
	if (!map || !source) return;
	const layers = [
		{
			id: 'clusters-shadow',
			type: 'circle',
			paint: {
				'circle-color': 'rgba(40, 41, 44, 0.3)', // (black900, 30% opacity)
				'circle-radius': 35,
				'circle-blur': 0.5,
				'circle-translate': [0, 2],
			},
		},
		{
			id: 'clusters',
			type: 'circle',
			paint: {
				'circle-color': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					'#E5F3F9', // Hover color (blue200)
					'#ffffff', // Default color (white)
				],
				'circle-radius': 30,
				'circle-stroke-color': '#0079AD', // (blue600)
				'circle-stroke-width': 3,
				'circle-color-transition': {
					duration: 300, // Duration of the transition in milliseconds
					delay: 0, // Delay before the transition starts in milliseconds
				},
			},
		},
		{
			id: 'cluster-count',
			type: 'symbol',
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-size': 20,
				'text-font': ['Arial Unicode MS Bold'],
			},
			paint: {
				'text-color': '#0079AD', // (blue600)
			},
		},
	];

	layers.forEach(layer => {
		map.addLayer({
			...layer,
			source,
			filter: ['has', 'point_count'],
		});
	});

	let hoveredClusterId = null;

	// Add hover effect for clusters
	map.on('mouseenter', 'clusters', e => {
		map.getCanvas().style.cursor = 'pointer';
		if (e.features.length > 0) {
			if (hoveredClusterId) {
				map.setFeatureState(
					{ source, id: hoveredClusterId },
					{ hover: false }
				);
			}
			hoveredClusterId = e.features[0].id;
			map.setFeatureState(
				{ source, id: hoveredClusterId },
				{ hover: true }
			);
		}
	});

	map.on('mouseleave', 'clusters', () => {
		map.getCanvas().style.cursor = '';
		if (hoveredClusterId) {
			map.setFeatureState(
				{ source, id: hoveredClusterId },
				{ hover: false }
			);
		}
		hoveredClusterId = null;
	});
}
