import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const ListItems = styled.ul`
	margin: 0;
	padding: 0 10px 0 0;
	list-style: none;
	position: relative;
	width: 100%;
	overflow: auto;
	height: calc(100vh - 360px);

	.fullscreen-active & {
		height: calc(100vh - 160px);
	}
`;

const ListItem = styled.li`
	border-radius: ${p => p.theme.utils.borderRadius};
	border: ${p => `1px solid ${p.theme.colors.grey200}`};
	background-color: ${p => p.theme.colors.white};
	padding: ${p => p.theme.spacing.tablet.xsmall};
	margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
	${p =>
		p.theme.media.large(css`
			margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
		`)}
	&.active-list-item {
		background-color: ${p => p.theme.colors.blue100};
	}
`;

const List = forwardRef(({ items, ...props }, ref) => {
	if (!items?.length) return null;
	return (
		<ListItems {...props} ref={ref} className="map-list">
			{items?.map((item, index) => (
				<ListItem key={index}>{item}</ListItem>
			))}
		</ListItems>
	);
});

export default List;
